<!-- <div class="container"> -->
    <!-- Page header -->
    <header>
        <qp-header
            (selected)="onClick($event)"
        >
        </qp-header>
    </header>

    <!-- Page content -->
     <div class="content height-sm">
        <!-- <div [ngClass.gt-xs]="{'height-sm': true}"> -->
            <router-outlet (activate)="onRouteActivated($event)"></router-outlet>
        <!-- </div> -->
    </div>

    <!-- Page footer -->
    <footer fxHide.lt-sm>
        <qp-footer></qp-footer>
    </footer>
<!-- </div> -->
<!-- 
<div class="content">
    <div
        ngClass.gt-xs="height-sm"
    >
        <router-outlet (activate)="onRouteActivated($event)"></router-outlet>
    </div>
</div> -->