import {
    Component,
    EventEmitter,
    Input,
    Output,

    inject
}                          from '@angular/core';
import {
    Observable,
    
    map,
    take
}                          from 'rxjs';

import { BaseComponent }   from '@Base/';
import { ButtonComponent } from '@GuiElements/button.component';
import { MessageService }  from '@Messaging/';


@Component({
    selector:    'qp-devicealert-button',
    templateUrl: 'devicealert-button.component.html',
    styleUrls:   [
        'devicealert-button.component.css'
    ],
    imports:     [
        ButtonComponent,
    ]
})
export class DeviceAlertButtonComponent extends BaseComponent
{
    @Input()
    public disabled: boolean                        = false;


    @Input()
    public id:       number | string | undefined;


    @Output()
    public readonly  result: EventEmitter<any>      = new EventEmitter();

    
    private readonly MessageService: MessageService = inject(MessageService);


    //
    // Public methods
    //
    public onClick(event?: any): void
    {
        console.debug("Button pressed - alerting device '" + this.id + "'");

        if (this.id) {
            // this.DeviceService.sendAlert();
            const obs = this.send(
                this.MessageService.messages.msgTypesAction.find,
                undefined,
                this.id
            ); // send
            if (obs instanceof Observable) this.sub = obs.subscribe(d => {
                console.debug("Alert resp");
                console.debug(d);
                this.result.emit(d);
            }); // subscribe
            else console.error("errror")
        }
    }


    //
    // Private methods
    //
    private send(cmd: string | undefined, attr?: any, d?: any | number): Observable<any> | undefined
    {
        return cmd
            // Will trigger message to server
            ? this.set(cmd, d, attr)

            : undefined;
    }


    public set(cmd: string, d: any | number | undefined, attr?: any | undefined, dontProcess: boolean = false): Observable<any> | undefined
    {
        const obj = {
            [this.MessageService.messages.msgTypesAttributes.id]: d,
        };
        if (attr) obj[this.MessageService.messages.msgTypesAttributes.extra] = attr;

        const obs: Observable<any> | undefined = (cmd != null)
            ? this.MessageService.sendMsgSet(
                cmd,
                this.MessageService.messages.msgTypesInfo.devices,
                obj
            )

            : undefined;

        return (obs instanceof Observable)
            ? obs
                .pipe(
                    take(1)
                ) // only listen once
                .pipe(
                    map((msg: any) => {
                        console.debug("Msg set success");
                        return msg && this.MessageService.messages.msgTypesAttributes.data in msg
                            ? msg[this.MessageService.messages.msgTypesAttributes.data]
                            : msg
                    }) // map
                )
            : undefined;
    }
}