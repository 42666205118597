import { Base } from '@Base/';


// All components reference this to get their appropriate Materal Design icon
export enum IconsMaterial {
    add                        = "add",
    arrow_drop_down            = "arrow_drop_down",
    campaign                   = "directions",

    connection_bluetooth       = "bluetooth",
    connection_ethernet        = "settings_ethernet",//network_cell",
    connection_mobilecell      = "cell_wifi",//network_cell",
    connection_wifi            = "wifi",//"network_wifi",
    connection_unknown         = "help_center",

    close                      = "close",
    dark_mode                  = "dark_mode",
    light_mode                 = "light_mode",
    dashboard                  = "dashboard",
    data_objects               = "grid_view",
    data_table                 = "table_view",
    device_groups              = "lists",//"bubble_chart",
    device_group               = "lists",//"bubble_chart",
    device_info                = "perm_device_information",
    devices                    = "devices",//"important_devices", //"devices_other", //"devices",
    devices_online             = "smartphone",
    device_alert               = "phonelink_ring",
    device_log                 = "notes",
    device_download_log        = "download",
    device_config              = "phonelink_setup",
    device_phone               = "smartphone",
    device_disconnect          = "signal_wifi_off",
    device_settings            = "settings",
    device_upgrade             = "upgrade",
    email                      = "alternate_email", //"email",
    help                       = "info", //"contact_support", //"help",
    house                      = "house",
    image                      = "image",
    insights                   = "equalizer",
    link                       = "link",
    logout                     = "logout", //"power_settings_new",
    map                        = "map",
    map_layers                 = "layers",
    menu                       = "menu",
    more_vert                  = "more_vert",
    mobile_cells               = "cell_tower", //"signal_cellular_alt",

    mobile_signal_level        = "signal_cellular_4_bar",
    mobile_signal_level_4_bar  = "signal_cellular_4_bar",
    mobile_signal_level_3_bar  = "signal_cellular_3_bar",
    mobile_signal_level_2_bar  = "signal_cellular_alt_2_bar",//"signal_cellular_2_bar",
    mobile_signal_level_1_bar  = "signal_cellular_alt_1_bar",
    mobile_signal_level_0_bar  = "signal_cellular_null",
    mobile_signal_level_no_sim = "signal_cellular_no_sim",

    mobile_technology_2g       = "g_mobiledata",
    mobile_technology_2gplus   = "e_mobiledata",
    mobile_technology_3g       = "3g_mobiledata",
    mobile_technology_3gh      = "h_mobiledata",
    mobile_technology_3ghplus  = "h_plus_mobiledata",
    mobile_technology_4g       = "4g_mobiledata",
    mobile_technology_4gplus   = "4g_plus_mobiledata",
    mobile_technology_lte      = "lte_mobiledata",
    mobile_technology_lteplus  = "lte_plus_mobiledata",
    mobile_technology_5g       = "5g",

    mobile_technology_cdma     = "1x_mobiledata",

    notifications              = "notifications",
    notification_clear         = "notifications_off",
    organisations              = "home_work", //"corporate_fare", //"location_city",
    organisation               = "home_work",
    person                     = "person",
    phone                      = "phone",
    power                      = "power_settings_new",
    power_off                  = "power_off",
    power_on                   = "power",
    power_reset                = "cached",
    refresh                    = "refresh",
    remove                     = "remove",
    report                     = "summarize",
    settings                   = "settings",

    sites                      = "storefront", //"store", //"location_city",
    start                      = "play_arrow",
    start_circle               = "play_circle",
    stop                       = "stop",
    stop_circle                = "stop_circle",
    store                      = "store",
    user                       = "account_circle", // "account_box", //"person",
    web_link                   = "link",
    web_link_open_in_new       = "open_in_new",
    wifi                       = "wifi",
 
    service_cloud              = "cloud",
    service_mobile_coverage    = "signal_cellular_alt",
    service_internet           = "speed",
    service_voice_calls        = "call",
    service_wifi_coverage      = "wifi",

    status_checked             = "radio_button_checked",
    status_unchecked           = "radio_button_unchecked",
    status_good                = "thumb_up",
    status_average             = "thumbs_up_down",
    status_poor                = "thumb_down",
    status_unknown             = "help",
 
    survey                     = "wifi_find",
    surveys                    = "wifi_find", //"network_ping", //"network_check", //"directions",
    survey_paused              = "pause",
    survey_started             = "radio_button_checked",
    survey_stopped             = "error",//check_small",
    survey_completed           = "check_circle",//check_small",
    survey_unknown             = "help",
    survey_report              = "summarize",

    test                       = "troubleshoot",//"query_stats",
    test_uplink                = "arrow_upward",
    test_downlink              = "arrow_downward",

    wifi_version_legacy        = "wifi",
    wifi_version_4             = "wifi",
    wifi_version_5             = "wifi",
    wifi_version_6             = "wifi",
    wifi_version_7             = "wifi",
    wifi_version_gig           = "wifi",
    wifi_version_unknown       = "wifi",
    wifi_connected             = "signal_wifi_4_bar"
} // IconsMaterial


export class Icons extends Base
{
    // Standard Google Maps icons
    private static readonly _baseIcons:      string = "https://maps.google.com/mapfiles/ms/micons/";
    private static readonly _cabs:           string = Icons._baseIcons   + "cabs.png";
    private static readonly _flag:           string = Icons._baseIcons   + "flag.png";
    private static readonly _pushpinBlue:    string = Icons._baseIcons   + "blue-pushpin.png";
    private static readonly _pushpinRed:     string = Icons._baseIcons   + "red-pushpin.png";
    

    // QP icons
    private static readonly _imagesBase:     string = "../assets/images/";
    private static readonly mobilePhoneBlue: string = Icons._imagesBase  + "phones_blue.png";
    private static readonly mobilePhoneGrey: string = Icons._imagesBase  + "phones_grey.png";
    private static readonly mobilePhoneRed:  string = Icons._imagesBase  + "phones_red.png";


    // Device icons
    public  static readonly deviceDefault:   string = Icons.mobilePhoneBlue;
    public  static readonly deviceDeleting:  string = Icons.mobilePhoneGrey;
    public  static readonly deviceNew:       string = Icons.mobilePhoneRed;
    public  static readonly deviceMonitor:   string = Icons.mobilePhoneGrey;
    public  static readonly deviceSurvey:    string = Icons.mobilePhoneRed;
    public  static readonly deviceTest:      string = Icons.mobilePhoneRed;
    
    public  static readonly ethernet:        string = Icons._imagesBase  + "ethernet.png";

    public  static readonly server:          string = Icons._imagesBase  + "cpu.png";
    public  static readonly wifi:            string = Icons._imagesBase  + "wifi.png";
    public  static readonly nextivityGo:     string = Icons._imagesBase  + "nextivity_go.png";
    public  static readonly nextivitySolo:   string = Icons._imagesBase  + "nextivity_go.png"; // [TBD]
    public  static readonly nextivityQuatra: string = Icons._imagesBase  + "nextivity_quatra.png";


    // Element icons
    public  static readonly organisation:    string = Icons._pushpinRed;
    public  static readonly site:            string = Icons._pushpinBlue;


    // Map routes icons
    public  static readonly routeLive:       string = Icons._cabs;
    public  static readonly routeStart:      string = Icons._pushpinBlue;
    public  static readonly routeEnd:        string = Icons._flag;


    // Mobile operator icons
    private static readonly _networkBase:    string = Icons._imagesBase  + "networkoperators/";
    public  static readonly icon3:           string = Icons._networkBase + "3.png";
    public  static readonly iconEe:          string = Icons._networkBase + "ee.png";
    public  static readonly iconO2:          string = Icons._networkBase + "o2.png";
    public  static readonly iconOoredoo:     string = Icons._networkBase + "ooredoo.png";
    public  static readonly iconTalkMobile:  string = Icons._networkBase + "talkmobile-trans.png";
    public  static readonly iconTalkTalk:    string = Icons._networkBase + "talktalk-trans.png";
    public  static readonly iconTescoMobile: string = Icons._networkBase + "tescomobile-trans.png";
    public  static readonly iconVirgin:      string = Icons._networkBase + "virgin.png";
    public  static readonly iconVodafone:    string = Icons._networkBase + "vodafone.png";


    //
    // Getter
    //
    static get networkBase()
    {
        return this._networkBase
    }
}