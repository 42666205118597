import {
    Pipe,
    PipeTransform
}                 from '@angular/core';

import { Dateq }  from '@Utils/';


@Pipe({
    name: 'durationsecs',
    pure: false,
})
export class DurationSecsPipe implements PipeTransform
{
    transform(d: number, compact: boolean = false, ms: boolean = false): string | undefined
    {
        if (! isNaN(d)) {
            if (compact) return Dateq.get(1000 * d)?.toISOString()?.substring(11, 19)
            else {
                const x = Dateq.get(1000 * d);
                if (x instanceof Dateq) {
                    let s = ""

                    // [TBD] Days?

                    let t = x.getUTCHours(); // x.getHours()
                    if (t > 0)
                        s += t + " hour" + (t !== 1 ? "s" : "");

                    t = x.getUTCMinutes();   // x.getMinutes()
                    if (t > 0)
                        s += (s.length > 0 ? ", " : "") + t + " min" + (t !== 1 ? "s" : "");
                    
                    t = x.getUTCSeconds();   // x.getSeconds()
                    const t2 = ms ? x.getUTCMilliseconds() : 0;
                    if (t > 0)
                        s += (s.length > 0 ? ", " : "")
                            + t
                            + (t2 ? "." + t2 : "")
                            + " sec"
                            + (t !== 1 || t2 ? "s" : "");

                    return s;
                }
            }
        }

        return undefined;

        // const shortTime = new Intl.DateTimeFormat(undefined, {
        //     timeStyle: "full",
        // });
        // // return (d instanceof Date) ? (d as Dateq).displayStr : undefined;
        // return (! isNaN(d))
        //     ? shortTime.format(Dateq.get(1000 * d))
        //     : undefined;
    }
}