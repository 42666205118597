import { MediaMatcher }          from '@angular/cdk/layout';
import { CommonModule }          from '@angular/common';
import {
    Component,
    ComponentRef,
    EventEmitter,
    isDevMode,
    Output
}                                from '@angular/core';
import { FlexLayoutModule }      from '@angular/flex-layout';
import {
    Router,
    RouterModule
}                                from '@angular/router';

import {
    Observable,
    Subject,

    filter
}                                from 'rxjs';

import { BaseComponent }         from '@Base/';

import { HeaderModule }          from '@Header/';
import { AuthenticationService } from '@Login/';
import { MainComponent }         from '@Main/';
import {
    MessageInterface,
    Messages,
    MessageService,
    SocketEvent
}                                from '@Messaging/';
import {
    ClientConfigService,
    DisplayModeService,
    StatusMessageService,
}                                from '@Misc/Services/';


@Component({
    selector:    'qp-app',
    templateUrl: 'app.component.html',
    styleUrls:   [
        'app.component.css'
    ],
    imports:     [
        CommonModule,     // ngClass
        FlexLayoutModule, // flex
        HeaderModule,     // qp-header and qp-footer
        RouterModule      // Router
    ]
})
export class AppComponent extends BaseComponent
{
    private static readonly msgConnectionLost:         string = "Lost connection";
    private static readonly msgConnectionRestored:     string = "Connection restored";
    private static readonly msgConnectionReconnecting: string = "Re-connecting";
    private static readonly msgLogoutImminent:         string = "Logout initiated";
    private static readonly reconMsgTimeout:           number = 10; // secs

    private                 routeComponent:            ComponentRef<any>;


    @Output()
    public selected: EventEmitter<any> = new EventEmitter();


    constructor(private readonly Router:                Router,
                private readonly MediaMatcher:          MediaMatcher,

                private readonly AuthenticationService: AuthenticationService,
                private readonly ClientConfigService:   ClientConfigService,
                private readonly DisplayModeService:    DisplayModeService,
                private readonly MessageService:        MessageService,
                private readonly StatusMessageService:  StatusMessageService)
                // private readonly StatusService:         qp_StatusService)
    {
        super();

        Router.initialNavigation();
    }


    //
    // Protected functions
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();
        
        this.StatusMessageService.clear();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.log("Initialising App component" + (isDevMode() ? " - Angular dev mode" : ""));

        if (this.DisplayModeService && Object(this.DisplayModeService).update instanceof Function) {
            this.DisplayModeService.update(!! this.MediaMatcher.matchMedia('(prefers-color-scheme: dark)')); // Set to preference by default
        }


        // Subscribe to ClientConfig to be informed if page needs reloading (e.g. version change)
        if (this.ClientConfigService && Object(this.ClientConfigService).reload$ instanceof Observable) {
            const obs: Observable<boolean> | undefined = this.ClientConfigService.reload$;
            if (obs instanceof Observable) this.sub = obs
                .pipe(filter((required: boolean): boolean => required))
                .subscribe((required: boolean): void => {
                    // If true, then trigger window.reload
                    console.info("Web client is out of date; triggering page re-load");

                    // if (window && window?.location && 
                    if (window?.location?.reload instanceof Function) {
                        window.location.reload();
                    }
                    else {
                        console.warn("Unable to initiate page reload");
                        console.warn(JSON.stringify(window));
                    }
                }); // subscribe
        }


        // this._setDisplayMode(this.Media.matchMedia('(prefers-color-scheme: dark)')); // Initially set
        // if (this.DisplayModeService && Object(this.DisplayModeService).mode$ instanceof Observable) {
        //     const obs: Observable<boolean> | undefined = this.DisplayModeService.mode$;
        //     if (obs instanceof Observable) this.sub = obs
        //         .subscribe((darkMode: boolean): void => {
        //             this._setDisplayMode(darkMode);
        //         }); // subscribe
        // }


        // Inform MessageService so it can access UserService
        // Has to be done here as no other component is available in both the
        // login and dashboard screen states
        this.MessageService.configure();

        let obs2: Observable<MessageInterface> | Observable<SocketEvent> | undefined = this.MessageService.socketEvent$;
        if (obs2 instanceof Observable) this.sub = obs2
            .subscribe((d: SocketEvent): void => {
                if (d) return this._processSocketEvent(d);
            }); // subscribe

        // Needed to process clientconfig msg, as Main isn't ready when it's received
        obs2 = this.MessageService.messageEvent$;
        if (obs2 instanceof Observable) this.sub = obs2
            .subscribe((d: MessageInterface): void => {
                if (d) return this._processMessageEvent(d);
            }); // subscribe
    }


    public onClick(event: any): void
    {
        this.selected.emit(event);

        if (this.routeComponent instanceof MainComponent) (this.routeComponent as MainComponent).onClick(event);
    }


    public onRouteActivated(componentRef: ComponentRef<any>): void
    {
        this.routeComponent = componentRef;
    }


    //
    // Private methods
    //
    private _processMessageEvent(dt: MessageInterface): void
    {
        if (dt) {
            switch (dt.message) {
                case Messages.msgTypesInfo.clientconfig:
                    this.ClientConfigService.update(dt.data);
            } // switch
        }
    }

    
    // [TBD] Don't move all this to message.service, or have status-message.service subscribe
    // Should be controlled from here, or via a new connection service, which looks after the connection
    // and then passes events to message.service.  Is this basically a re-name?
    private _processSocketEvent(dt: SocketEvent): void
    {
        if (dt) {
            switch (dt.d) {
                case MessageService.events.connect:
                    this.StatusMessageService.clear(); // Clear any message informing of loss of contact to server // this.StatusService.clearStatus()
                    if (! this.AuthenticationService.isConfigured) this.AuthenticationService.configure(); // must be done only once MessageService connected
                break;

                case MessageService.events.reconnect:
                    this.StatusMessageService.set(
                        AppComponent.msgConnectionRestored,
                        StatusMessageService.Level.Info,
                        AppComponent.reconMsgTimeout
                    ); // set

                    // Temp - force site reload
//                    console.info("Reloading web site due to server restart");
//                    $window.location.reload(true);

                    // this.sub =
                    //     timer(AppComponent.reconMsgTimeout * 1000)
                    //     .subscribe((d: number): void => {
                    //         this.StatusMessageService.clear();
                    //         // this.StatusService.clearStatus();
                    //     }); // subscribe

                        // interval(AppComponent.reconMsgTimeout * 1000)
                        // .pipe(take(1))
                        // .subscribe((d: number): void => {
                        //    this.StatusMessageService.clear();
                        // //     this.StatusService.clearStatus();    
                        // }); // subscribe
                break;

                case MessageService.events.reconnect_attempt:
                    this.StatusMessageService.set(
                        AppComponent.msgConnectionReconnecting
                            + (dt.val1 && dt.val2 ? "(" + dt.val1 + "/" + dt.val2 + ")": ""),
                        StatusMessageService.Level.Warn,
                        -1
                    ); // set
                break;

                case MessageService.events.disconnect:
                    // Display message informing of loss of contact to server
                    this.StatusMessageService.set(
                        AppComponent.msgConnectionLost,
                        StatusMessageService.Level.Error,
                        -1
                    ); // set
                break;

                case MessageService.events.logout:
                    console.debug(dt);

                    // Display message informing imminent logout as requested by server
                    this.StatusMessageService.set(
                        dt.val1 ? dt.val1 : AppComponent.msgLogoutImminent,
                        StatusMessageService.Level.Warn,
                        dt.val2 ? dt.val2 : AppComponent.reconMsgTimeout,
                        false
                    ); // set
                break;
            } // switch
        } // if data
    }
}