@if (d) {
    <qp-table-data
        [nullValues]=false
        [data]="
            [
                { k: AppStrings.Monitoring_Running, v: d.monitoringrunning | yesno }
            ]
        "

        (selected)="select($event)"
    >
    </qp-table-data>
    <!-- <mat-icon class="tab-icon">{{ Icons.link }}</mat-icon> -->
}