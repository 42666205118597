import { Injectable }                        from '@angular/core';
import {
    Observer,
    Observable,
    Subject,
    Subscriber,
    Subscription,

    filter,
    take
}                                            from 'rxjs';

import { IconsService }                      from '@Icons/';
import {
    Messages,
    MessageService,
    MessageProcessingService 
}                                            from '@Messaging/';
import {
    DataServiceEvents,
    DataService,
}                                            from '@Misc/Services/';
import { MobileNetworkBands }                from '@MobileNetworks/mobile-network-bands';
import { MobileNetworksService }             from '@MobileNetworks/mobile-networks.service';
import { Utils }                             from '@Utils/';

import {
    Device,
    DeviceCommon,
    DeviceHelper,
    DeviceI,
    ElementStatus,
    Notification,
    NotificationHelper
}                                             from '@ObjElements/';
import { DeviceHelper as DeviceHelperCommon } from '@Common/';
import { DevicesList }                        from './devices-list.class';


// declare var angular: any;

// [TBD] notify if device updated


@Injectable({
    providedIn: 'root'
})
export class DeviceCollectionService extends DataService
{
    private static readonly _title: string   = "DeviceCollection";

    private        readonly _attrs: string[] =
    [
        'id',
        'description',
        'location',
        'manufacturer',
        'model',
        'deviceSoftwareLoad'
    ]; // _attrs


    private _mobileNetworksDataAvailable: boolean = false;

    protected _deviceAdded$:   Subject<string>;
    protected _deviceDeleted$: Subject<string>;

 
    public constructor(private readonly IconsService:             IconsService,
                                        MessageService:           MessageService,
                       private readonly MessageProcessingService: MessageProcessingService,
                       private readonly MobileNetworksService:    MobileNetworksService)
    {
        super(MessageService, DeviceCollectionService._title, Messages.msgTypesInfo.devices);

        this._deviceAdded$   = new Subject<string>();
        this._deviceDeleted$ = new Subject<string>();


        // Subscribe to be informed about socket reconnection; if/when it
        // it happens, refresh this service to ensure is up-to-date
        let obs: Observable<boolean> | undefined = this.MessageService.connected$
        if (obs instanceof Observable) this.sub = obs
            .pipe(
                filter((recon: boolean): boolean => recon == true)
            ) // pipe
            .subscribe((recon: boolean): void => {
                this.refresh();
            }); // subscribe

        obs = this.MobileNetworksService.dataAvailable;
        if (obs instanceof Observable) this.sub = obs
            .subscribe((available: boolean): void => {
                // Inform DeviceLists
                if (this._mobileNetworksDataAvailable = available) {
                    this.lists.forEach((dl: DevicesList): void => {
                        if (dl) dl.setDataAvailable(this.MobileNetworksService);
                    }); // forEach
                }
            }); // subscribe

        // Listen for events
        obs = this.MessageProcessingService.getObs$(Messages.msgTypesInfo.devices);
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: any): void => {
                this.processEvent(d);
            }); // subscribe

        obs = this.MessageProcessingService.getObs$(Messages.events.device);
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: any): void => {
                this.processDevice(DeviceHelperCommon.get(d));
            }); // subscribe
    }


    //
    // Interface
    //

    // Override
    public get(id: string | number, type?: any): Device | undefined
    {
        return this.getDevice(id);
    }


    // Override
    public override getAll(type?: any): Device[] | DevicesList[] | undefined
    {
        return type ? this.getDevicesByType(type) : this.lists;
    }
    

    // Override
    public override getObservable(type: string, event: DataServiceEvents): Observable<any> | undefined
    {
        const dl: DevicesList | undefined = this.getList(type);
        return (dl instanceof DevicesList) ? dl.getObservable(type, event) : undefined;
        // return (dl instanceof DevicesList && dl[event]) ? dl[event] : undefined;
    }


    // Override
    protected processData(data: any): void
    {
        this.update(data && data.data ? data.data : data);
    }


    //
    // Getters & setters
    //
    public get deviceAdded$(): Observable<string>
    {
        return this._deviceAdded$.asObservable();
    }


    public get deviceDeleted$(): Observable<string>
    {
        return this._deviceDeleted$.asObservable();
    }


    private get attrs(): string[]
    {
        return this._attrs;
    }


    private get deviceLists(): { [key: string]: any }
    {
        return this.dataI;// this._deviceLists;
    }


    private set deviceList(dl: DevicesList)
    {
        if (dl) this.addList(dl);
    }


    private get lists(): DevicesList[]
    {
        return Object.values(this.deviceLists);
    }


    private get total(): number
    {
        return Object.keys(this.deviceLists).length;
    }


    //
    // Public methods
    //
    public deleteDevice(id: string, type?: string): Device | undefined
    {
        const dl: DevicesList | undefined = this.getListForDevice(id);
        return dl ? dl.delete(id) : undefined;

        // No need to delete list; observable will be triggered above on deleted case and list deletion will occur there
    }


    private deviceListNotify(id: string): Observable<DevicesList | undefined>
    {
        // Return an observable for deviceList with id
        return new Observable((sub: Subscriber<DevicesList | undefined>): any => {
            let   obs:  Observable<any> | undefined = undefined;
            const subL: Subscription                = new Subscription();

            const dl: DevicesList | undefined = this.getList(id);
            if (sub instanceof Subscriber && dl instanceof DevicesList) sub.next(dl);

            // Watch for deviceList added
            if ((obs = this[DataServiceEvents.added]) instanceof Observable) subL.add(obs
                .pipe(
                    filter((d: string): boolean => d === id)
                )
                .subscribe((d: string): void => {
                    console.debug("DeviceList added: " + d);
                    if (sub instanceof Subscriber) sub.next(this.getList(id));
                }) // subscribe
            ); // add

            if ((obs = this[DataServiceEvents.deleted]) instanceof Observable) subL.add(obs
                .pipe(
                    filter((d: string): boolean => d === id)
                )
                .subscribe((d: string): void => {
                    console.debug("DeviceList deleted: " + d);
                    if (sub instanceof Subscriber) sub.next(undefined);
                }) // subscribe
            ); // add

            return function unsubscribe(): void {
                // console.debug("Cleaning up subscription (1)");
                subL.unsubscribe();
            };
        }); // new Observable
    }


    // Not used
    // public deviceNotify(id: string, type: string): Observable<Device>
    // {
    //     const subL: Subscription = new Subscription();
        
    //     // Return an observable for device with id and type
    //     return new Observable((sub: Subscriber<Device>): any => {
    //         let obs;

    //         const d: Device | undefined = this.getDevice(id);
    //         if (d) sub.next(d);
            
    //         // Watch for deviceList added/deleted
    //         if (obs = this.deviceListNotify(type)) subL.add(obs
    //             .pipe(
    //                 filter((dl: DevicesList): boolean => typeof dl !== 'undefined')
    //             )
    //             .subscribe((dl: DevicesList): void => {
    //                 // List available
    //                 console.log("List " + type + " added")
    //                 console.log(dl)
    //                 const d: Device | undefined = dl.get(id);
    //                 if (d instanceof Device) sub.next(d);

    //                 if (dl instanceof DevicesList) {
    //                     // Watch for device added
    //                     if ((obs = this.getObservable(type, DataServiceEvents.added)) instanceof Observable) {
    //                         subL.add(obs
    //                             .pipe(
    //                                 filter((d: string): boolean => d === id)
    //                             )
    //                             .subscribe((d: string):void => sub.next(this.getDevice(d)) )
    //                         );
    //                     }

    //                     // Watch for device deleted
    //                     if ((obs = this.getObservable(type, DataServiceEvents.deleted)) instanceof Observable) {
    //                         console.log(obs)
    //                         subL.add(obs
    //                             .pipe(
    //                                 filter((d: string): boolean => d === id)
    //                             )
    //                             .subscribe((d: string): void => sub.next(undefined) )
    //                         );
    //                     }
    //                 } // dl
    //             }) // subscribe
    //         ); // add

    //         // // onNext()
    //         // (d: string): void => {
    //         //     console.log("Device added: " + type + " - " + id);
    //         //     sub.next(this.getDevice(d));
    //         // },
    //         // // OnError()
    //         // (): void => {
    //         //     console.log("h2");
    //         // },
    //         // // OnComplete()
    //         // (): void => {
    //         //     console.log("h3");
    //         // }

    //         return function unsubscribe(): void {
    //             // console.log("Cleaning up subscription (2)");
    //             subL.unsubscribe();
    //         };
    //     }); // new Observable
    // }


    public deletePendingDevice(id: string, type: string): Device | undefined
    {
        const dev: Device | undefined = this.getDevice(id);
        if (dev) {
            dev.pendingDelete = true;
           // d.status        = ElementStatus.Offline;
        }
        else {
            console.log("Device '" + id + "' not found; cannot set to pendingDelete");
        }

        return dev;
    }


    private getDevice(id: string | number): Device | undefined
    {
        const dl: DevicesList | undefined = this.getListForDevice(id);
        return dl ? dl.getDevice(id) : undefined;
    }



    // Returns observable, can be used to get data from server re: offline device
    public getDeviceAsync(id: string | number | undefined, offlineFallback: boolean = false): Observable<Device | undefined>
    {
        return new Observable((sub: Subscriber<Device | undefined>): any => {
            // let subL: Subscription;

            // Query server
            const data: any = {};
            // if (typeof id === 'string') data.serialNum = id
            // else                        data.id        = id;
            data.id = id;

            const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
                Messages.msgTypesInfo.devices,
                data
            ); // sendMsgGet

            if (obs) {
                console.debug("Looking up device on server: " + id);

                this.sub = obs
                    .pipe(
                        take(1)
                    )
                    .subscribe((data: any): void => {
                        if (data && data.data) Object.values(data.data).forEach((d: any): void => {
                            if (d) {
                                const dev: Device | undefined = DeviceHelper.get(d, this.IconsService);
                                if (sub instanceof Subscriber) {
                                    sub.next((dev instanceof Device && dev.id === id) ? dev : undefined);

                                    // Now need to keep listening for device updates?
                                    
                                    sub.complete();
                                }
                            }
                        }); // forEach
                    }); // subscribe
            }

            return function unsubscribe() {
                // if (subL instanceof Subscription) subL.unsubscribe();
            }
        }); // Observable.create()
    }


    //// Returns observable, can be used to get data from server re: offline device
    // public getDeviceAsync(id: string | number | undefined, offlineFallback: boolean = false): Observable<Device | undefined>
    // {
    //     let subL: Subscription;
    //     return new Observable((sub: Subscriber<Device | undefined>): any => {
    //         const obs: Observable<boolean> | undefined = this[DataServiceEvents.loading];
    //         if (obs instanceof Observable) subL = obs
    //             .pipe(
    //                 filter((s: boolean): boolean => ! s)
    //             )
    //             .subscribe((s: boolean): void => {
    //                 // console.error(id)
    //                 // console.error(Number(id))
    //                 const d: Device | undefined = id ? this.getDevice(id) : undefined;
    //                 if      (d instanceof Device) {
    //                     if (sub instanceof Subscriber) {
    //                         sub.next(d);
    //                         sub.complete();
    //                     }
    //                 }
    //                 else if (offlineFallback) {
    //                     // If not active, query server
    //                     const data: any = {};
    //                     // if (typeof id === 'string') data.serialNum = id
    //                     // else                        data.id        = id;
    //                     data.id = id;

    //                     const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
    //                         Messages.msgTypesInfo.devices,
    //                         data
    //                     ); // sendMsgGet

    //                     if (obs) {
    //                         console.debug("Looking up offline device on server: " + id);

    //                         this.sub = obs
    //                             .pipe(
    //                                 take(1)
    //                             )
    //                             .subscribe((data: any): void => {
    //                                 if (data && data.data) Object.values(data.data).forEach((d: any): void => {
    //                                     if (d) {
    //                                         const dev: Device | undefined = DeviceHelper.get(d, this.IconsService);
    //                                         if (sub instanceof Subscriber) {
    //                                             sub.next((dev instanceof Device && dev.id === id) ? dev : undefined);
    //                                             sub.complete();
    //                                         }
    //                                     }
    //                                 }); // forEach
    //                             }); // subscribe
    //                     }
    //                 } // offlineFallback
    //             }); // subscribe

    //         return function unsubscribe() {
    //             if (subL instanceof Subscription) subL.unsubscribe();
    //         }
    //     }); // Observable.create()
    // }

    
    // // Not used
    // public getDeviceCell(id: string): object | undefined
    // {
    //     const d: Device | undefined = this.getDevice(id);
    //     if (d instanceof Device) {
    //         return {
    //             // plmn:     d.plmn,
    //             // gcid:     d.gcid,
    //             // rat:      d.rat,
    //             // cellCode: d.cellCode,
    //             // areaCode: d.areaCode
    //         };
    //     }
    //     else {
    //         console.log("Device '" + id + "' not found");
    //     }

    //     return undefined;
    // }


    public getDevices(): Device[]
    {
        const res: Device[] = [];

        Object.keys(this.deviceLists).forEach((id: string) => {
            if (this.deviceLists[id]) {
                // console.log(res);
            // console.log(this.deviceLists[id].devices);
                res.push(this.deviceLists[id].devices);
                // console.log(res);
            }
        }); // forEach

        return res;
    }


    public getDevices2(): Device[]
    {
        const devs: Device[] = [];

        this.getDevices().forEach((d: Device) => {
            if (d.pendingDelete) {
                (<any>d).text = d.id + " - " + d.description;
                devs.push(d);
            }
        }); // forEach

        return devs;
    }


    public getDevicesByType(type: string): Device[] | undefined
    {
        const dl: DevicesList | undefined = this.getList(type);
        return (dl instanceof DevicesList) ? dl.devices : undefined;
    }


    public getDeviceIds(): string[]
    {
        const ids: string[] = [];

        this.getDevices().forEach((d: Device) => {
            if (d.pendingDelete) ids.push(d.id);
        }); // forEach

        return ids;
    }


    private getListForDevice(id: string | number): DevicesList | undefined
    {
        return id
            ? this.lists.find((l: DevicesList) => {
                return l instanceof DevicesList && l.get(id);
            }) // forEach

            : undefined;
    }


    // Return string of type of device
    public getTypeOfDevice(id: string): Observable<string>
    {
        return new Observable((sub: Subscriber<any>): any => {
            const obs: Observable<boolean> | undefined = this.loading;
            if (obs instanceof Observable) this.sub = obs
                .pipe(
                    filter((status: boolean): boolean => status == false)
                )
                .pipe(
                    take(1)
                )
                .subscribe((status: boolean): void => {
                    const d: Device | undefined = this.getDevice(id);
                    if (sub instanceof Subscriber) {
                        sub.next(d ? d.type : undefined);
                        sub.complete();
                    }
                }); // subscribe
        }); // Observable
    }


    // [TBD]
    // Override
    public override refresh2(startIndex?: number, quantity?: number): void
    {
        this.refresh();
    }


    // Override
    public override refresh(): Subscription | undefined
    {
        // Don't call super() at the moment

        const status: ElementStatus = ElementStatus.Online;
        console.debug("Refreshing " + this.Title + " service - getting current devices: " + status.status);

        // Use observable
        this._dataLoading$.next(true);
        // const obs: Observable<any> = this.MessageService.sendMsg(
        //     Messages.msgTypes.getdata,
        //     {
        //         type:   Messages.msgTypesSub.devices,
        //         status: status
        //     }
        // );

        // console.debug(this.MessageService);
        // console.debug(this.MessageService.messages.msgTypesInfo);
        // console.debug(Messages.msgTypesInfo);

        const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
            Messages.msgTypesInfo.devices,
            { [Messages.msgTypesAttributes.state]: status.status }
        ); // sendMsgGet

        return obs instanceof Observable
            ? (this.sub = obs.subscribe((data: any): void => {
                if (data) {
                    this.update(data.data ? data.data : data, true);
                    this._dataLoading$.next(false);
                }
              }) ) // subscribe

            : undefined;
    }



    // Override
    protected override processMsgData(d: any[]): void
    {
        console.error(d)
        super.processMsgData(d);

        this.update(Array.isArray(d) ? {[DeviceCommon.Types.mobilephone]: d} : ((d as any)?.data ? (d as any).data : d), true);

        // if (Array.isArray(d)) {
        //     console.debug("Updating " + this.Title + " service with data: " + d.length);
        //     this.clear(false);
        //     Object.values(d).forEach((v): void => {
        //         this.process(v, false);
        //     }); // forEach
        //     this.updateObservables(false);
        // }
        // else {
        //     console.warn("No data received to update " + this.Title + " service");
        // }
    }


    public update(d: any, clear: boolean = false): boolean
    {
        console.debug(d);

        // Check for valid device types
        if (d) {
            const keys = Array.isArray(d) ? [ DeviceCommon.Types.mobilephone ] : (d === 'object' && Object.keys(d).length > 0 ? Object.keys(d) : undefined);
            if (keys) {
                console.debug("Updating DeviceCollection service with data - device types: " + keys);

                // Go through each device type object
                keys.forEach((t: string): void => {
                    const o: DeviceI[] = Array.isArray(d) ? d : (d[t] ? d[t] : []);
                    if (Array.isArray(o)) {
                        console.debug("Updating '" + t + "' DeviceCollection service with data: quantity = " + o.length);
                        // If more than once device received, assume not update so delete any existing devices of that type
                        if (clear || o.length > 1) this.clearList(t); // don't delete list, just clear it otherwise affects map view
                    
                        // Go through each device in type's arrasy
                        o.forEach((dev: DeviceI): boolean => {
                            return this.addDevice(DeviceHelper.get(dev, this.IconsService), false) ? true : false; // add device, will add to correct type list
                            // return this.addDevice(d, t) ? true : false; // add device, will add to correct type list
                        }); // forEach
        this.getDevices(); // will trigger console.log
                    }
                    else {
                        console.log("No data received to update '" + t + "' DeviceCollection service");
                        console.log(o);
                    }
                }); // forEach device type
                this.updateObservables();
            } // if keys
        } // if d

        return true;//(o ? true : false);
    }


    //
    // Protected methods
    //
    
    // Overrride
    protected override updateObservables(status: boolean = true): void 
    {
        // Don't call super()

        if (this.elementsNum$ instanceof Observable)            this.elementsNum$.next(this.size);    
        if (status && this.dataElements$ instanceof Observable) this.dataElements$.next(this.dataE);

        // Special case to send object instead of array; DeviceListComponent understands this
        const d: any = {};
        this.dataK.forEach((t: string): void => {
            d[t] = {};
        }); // forEach
        this.status$.next(d);
    }


    //
    // Private methods
    //
    private addDevice(d: Device | undefined, inform: boolean = true): Device | undefined
    {
        if (! (d instanceof Device)) return undefined;

        // Create deviceList if not already exists
        let dl: DevicesList | undefined = this.getList(d.type);
        let dlNew: DevicesList | undefined = undefined;
        if (! (dl instanceof DevicesList)) {
            // Create new list
            dlNew = new DevicesList(d.type, this.IconsService);
            if (dlNew instanceof DevicesList && this._mobileNetworksDataAvailable) dlNew.setDataAvailable(this.MobileNetworksService);
        }

        // // If new list, inform listeners new device type available - must do *after* adding device?
        if (dlNew instanceof DevicesList) this.deviceList = (dl = dlNew);  // New DL, will trigger observables

        return (dl instanceof DevicesList)
            ? dl.add(d, inform)
            : undefined; // add *after* list
    }


    private addList(dl: DevicesList | undefined, update: boolean = true): DevicesList | undefined
    {
        if (dl instanceof DevicesList) {
            const type: string = dl.type;
            if (type) {
                console.debug("Adding '" + type + "' DeviceList");

                // Delete existing list (if it exists)
                this.deleteList(type, false); // don't update observables in this case

                // Listen for number of devices on list; delete list if it falls to 0
                let obs: Observable<any> | undefined = dl[DataServiceEvents.number];
                if (obs instanceof Observable) dl.sub = obs
                    .subscribe((num: number): void => {
                        // {TBD] Don't do this for now as affects map; just leave list empty}
                        // if (num <= 0) this.deleteList(type); // delete list, will update observables
                    }); // subscribe

                // Listen for devices added to list
                obs = dl[DataServiceEvents.added];
                if (obs instanceof Observable) dl.sub = obs
                    .subscribe((d: string): void => {
                        this._deviceAdded$.next(d);
                    }); // subscribe

                // Listen for devices deleted from list
                obs = dl[DataServiceEvents.deleted];
                if (obs instanceof Observable) dl.sub = obs
                    .subscribe((d: string): void => {
                        this._deviceDeleted$.next(d);
                    }); // subscribe
                
                // Add new list
                this.deviceLists[type] = dl;

                if (update) {
                    if (this._elementAdded$ instanceof Observable) this._elementAdded$.next(type);
                    this.updateObservables();
                }
            } //type
            else {
                console.error(dl);
            }
        }

        return dl;
    }


    private clearList(type: string, update: boolean = true): DevicesList | undefined
    {
        const dl: DevicesList | undefined = this.getList(type);
        if (dl instanceof DevicesList) {
            console.debug("Clearing '" + dl.type + "' DeviceList");
            dl.deleteAll();
            
            if (update) this.updateObservables();
        }

        return dl;
    }


    private deleteList(type: string, update: boolean = true): DevicesList | undefined
    {
        const dl: DevicesList | undefined = this.getList(type);
        if (dl instanceof DevicesList) {
            console.debug("Deleting '" + dl.type + "' DeviceList");
            dl.cleanUp(); // will clean up subscriptions
            if (this.deviceLists[type as keyof typeof this.deviceLists]) delete this.deviceLists[type as keyof typeof this.deviceLists];

            if (update) {
                if (this._elementDeleted$ instanceof Observable) this._elementDeleted$.next(type);
                this.updateObservables();
            }
        }

        return dl;
    }


    private getList(type: string): DevicesList | undefined
    {
        return this.lists.find((l: DevicesList): boolean => {
            return l instanceof DevicesList && l.type == type;  
        }); // find
    }

        
    private processDevice(d: any): Device | undefined
    {    
        // Try and get device object
        const dev: Device | undefined = DeviceHelper.get(d, this.IconsService);

        // Now find devicelist containing object
        const dl: DevicesList | undefined =
            (dev instanceof Device)
                ? this.getListForDevice(dev.id)
                : (d ? this.getListForDevice(d.serialnumber) : undefined);

        // If found, update, else add new device
    // if (dl instanceof DevicesList) console.error(dl.type);
        return ((dl instanceof DevicesList) ? dl.updateDevice(dev ? dev : d): this.addDevice(dev)) as Device;

        //    const dl: DevicesList = this.getListForDevice(
        // dev
        //     ? (dev instanceof DeviceCommon
        //          ? (dev as DeviceCommon).id
        //          : (dev.serialNum ? dev.serialNum : dev[Messages.attributes.serialnumber]))
        //     : undefined
        // );
    }


    private processEvent(d: any): Device | undefined
    {
        if      (d instanceof DeviceCommon) return this.processDevice(d);

        else if (d) {
            const msgType: string = d[Messages.msgTypesAttributes.message];
            if (msgType) {
                const msgData = d[Messages.msgTypesAttributes.data];
                switch (msgType) {
                    // case Messages.events.device:
                    //     console.log("STU Update device");
                    //     console.log(d);
                    //     // this.update(d && d.data ? d.data : d);
                    //     break;

                    
                    // [TBD] Device add
                    case Messages.msgTypesInfo.init:
                        d.status = ElementStatus.Online; // required as status not sent from server in this case
                        return this.addDevice(DeviceHelper.get(d, this.IconsService));
                        // this.addDevice(d, DeviceCommon.Types.mobilephone);

                    // Device update
                    case Messages.events.device:
                        return this.processDevice(DeviceHelperCommon.get(msgData));

                    // New devices
                    case Messages.msgTypesInfo.devices:
                        this.update(d && msgData ? msgData : d);
                    break;

                    case Messages.msgTypesInfo.devicenotifications:
                        const data = d ? msgData : undefined
                        if (Array.isArray(data)) data.forEach((d2: any): void => {
                            const n: Notification = NotificationHelper.get(d2);
                            if (n instanceof Notification) {
                                // Find device and send the notification to it
                                const dev: Device | undefined = this.getDevice(n.systemId);
                                if (dev instanceof Device) dev.processNotification(n);
                                else {
                                    console.log("Device not found for notification: " + n.systemId);
                                    console.debug(n)
                                }
                            }
                        }); // forEach
                    break;

                    case Messages.msgTypesInfo.disconnect:
                        return this.deleteDevice(d[Messages.attributes.serialnumber], DeviceCommon.Types.mobilephone);

                    case Messages.msgTypesInfo.disconnectwait:
                        this.deletePendingDevice(d[Messages.attributes.serialnumber], DeviceCommon.Types.mobilephone);
                    break;

                    default:
                        return this.processDevice(d);
                } // switch
            }
            else {
                console.warn("Unable to process message: " + msgType);
                console.warn(d);
            }
        } // d.message

        else {
            console.warn("Unable to process event");
            console.warn(d);
        }

        return undefined;
    }


    // Deprecated - still used by device-info.component
    public getDevicesForTable(type?: boolean, serialNum?: string, mode?: boolean): any
    {
        const devicesLocal: any[] = [];

        let attrs = [
            'serialNum',
            'connectedDate',
            'manufacturer',
            'model',
            'deviceSoftwareLoad',
            'batteryLevel',
        ];

        let attrs2: string[] = [];
            //'simSlotNum',
        //];

        if (mode) {
            attrs = [
                'serialNum',
                'connectedDate',
                'manufacturer',
                'model',
                'deviceSoftwareLoad',
                'batteryLevel',
                'testResultType',
                'connectionType',
                'latitude',
                'longitude'
            ];

            attrs2 = [
                'simSlotNum',
                'imei',
                'imsi',
                'rat',
                'operator',
                'plmn',
                'gcid',
                'areaCode',
                'cellCode',
                'band'
            ];
        }

        // Attrs names (i.e. columns)
        if (type && this.total) {
            //attrs.unshift('map'); // add to front of attr array
            var len: number = attrs.length
            for (let a = 0; a < len; ++a) {
                devicesLocal[a] = {};
                //devicesLocal[a].displayName = attrs[a].charAt(0).toUpperCase() + attrs[a].slice(1);
                let at = attrs[a];
                if      (at == 'testResultType')    at = "testRunning";
                else if (at == 'phoneSoftwareLoad') at = "deviceSoftwareLoad";
                devicesLocal[a].orig = at;
                //devicesLocal[a].displayName = devicesLocal[a].orig.charAt(0).toUpperCase() + devicesLocal[a].orig.slice(1);
                devicesLocal[a].displayName = Utils.stringCapFirstLetter(devicesLocal[a].orig);
            } // for

            for (let b = 0, len2 = attrs2.length; b < len2; ++b) {
                devicesLocal[b + len] = {};
                let at = attrs2[b];
                if (at == 'rat') at = "technology";
                //devicesLocal[b + len].displayName = attrs2[b].charAt(0).toUpperCase() + attrs2[b].slice(1);
                devicesLocal[b + len].orig = at;
                //devicesLocal[b + len].displayName = devicesLocal[b + len].orig.charAt(0).toUpperCase() + devicesLocal[b + len].orig.slice(1);
                devicesLocal[b + len].displayName = Utils.stringCapFirstLetter(devicesLocal[b + len].orig);
            } // for
            if (mode) {
                devicesLocal[attrs2.length + len] = {};
                devicesLocal[attrs2.length + len].orig = "lcid";
                //devicesLocal[attrs2.length + len].displayName = devicesLocal[attrs2.length + len].orig.charAt(0).toUpperCase() + devicesLocal[attrs2.length + len].orig.slice(1);
                devicesLocal[attrs2.length + len].displayName = Utils.stringCapFirstLetter(devicesLocal[attrs2.length + len].orig);

                devicesLocal[attrs2.length + len + 1] = {};
                devicesLocal[attrs2.length + len + 1].orig = "neid";
                //devicesLocal[attrs2.length + len + 1].displayName = devicesLocal[attrs2.length + len + 1].orig.charAt(0).toUpperCase() + devicesLocal[attrs2.length + len + 1].orig.slice(1);
                devicesLocal[attrs2.length + len + 1].displayName = Utils.stringCapFirstLetter(devicesLocal[attrs2.length + len + 1].orig);
            }
        }

        // Attrs values (i.e. rest of cells)
        else {
            // for (let i = 0; i < this.total; ++i) {
            //     if (this.devices[i] && (! this.devices[i].pendingDelete || this.devices[i].pendingDelete != true)) {
            //         if (! serialNum || this.devices[i].id == serialNum) {

            //             let p = {};
            //             for (let a = 0, len2 = attrs.length; a < len2; ++a) {
            //                 p[attrs[a]] = {};
            //                 if (this.devices[i][attrs[a]] || attrs[a] == 'connectedDate') {
            //                     p[attrs[a]].val = this.devices[i][attrs[a]];

            //                     switch (attrs[a]) {
            //                         // Fix connectedDate
            //                         case 'connectedDate':
            //                             if (this.devices[i].reconnectedDate) {
            //                                 p[attrs[a]].val = this.devices[i].reconnectedDate + " (*)";
            //                             }
            //                             break;

            //                         // Add device type to (e.g. 'Android') to software version before returning
            //                         case 'deviceSoftwareLoad':
            //                             p[attrs[a]].val = this.devices[i].deviceSubType + " " +  p[attrs[a]].val;
            //                             break;

            //                         // Add '%' symbol
            //                         case 'batteryLevel':
            //                             p[attrs[a]].colour = this.getBatteryColour(p[attrs[a]].val);
            //                             p[attrs[a]].val +=  " %";
            //                             if (this.devices[i] instanceof DeviceWithBattery) {
            //                                 // const d: DeviceWithBattery = this.devices[i] as DeviceWithBattery;
            //                                 // // Add charging indicator
            //                                 // if (d.batteryCharging && d.batteryConnected) {
            //                                 //         p[attrs[a]].val +=  " (" + d.batteryConnected + ")";
            //                                 // }
            //                             }
            //                             break;

            //                         // Fix testRunning column
            //                         case 'testResultType':
            //                             if ((this.devices[i] as any).testResultType.indexOf("status") < 0) {
            //                                 p[attrs[a]].val = (this.devices[i] as any).testResultType.replace("start", "").replace("progress", "");
            //                             }
            //                             else {
            //                                 p[attrs[a]].val = "";
            //                             }
            //                             break;
            //                     } // switch
            //                 }
            //             } // for

            //             if (this.devices[i] instanceof DeviceMobilePhone) {
            //                 const d: DeviceMobilePhone = this.devices[i] as DeviceMobilePhone;
            //                 if (d.sims) {
            //                     var q: any; // [TBD] do here to only get 'first' sim data
            //                     Object.keys(d.sims).forEach((b) => {
            //                         if (! q) {
            //                             q = {};
            //                             //angular.extend(q, p);
            //                             for (var c = 0, len4 = attrs2.length; c < len4; ++c) {
            //                                 q[attrs2[c]] = {};
            //                                 if (d.sims[b][attrs2[c]]) {
            //                                     q[attrs2[c]].val = d.sims[b][attrs2[c]];

            //                                     // Add frequency band information
            //                                     if (attrs2[c] == 'band') {
            //                                         const bandInfo = MobileNetworkBands.getBandInfo(d.sims[b].rat, d.sims[b][attrs2[c]]);
            //                                         q[attrs2[c]].val += bandInfo ? " (" + bandInfo + ")" : "";
            //                                     }
            //                                 }
            //                             }

            //                             if (mode) {
            //                                 q["lcid"] = {}; // lcid
            //                                 q["neid"] = {}; // neid

            //                                 if      (d.sims[b].rat == "umts") {
            //                                     q["lcid"].val =  d.sims[b]["gcid"] & 0xffff;
            //                                     q["neid"].val = (d.sims[b]["gcid"] >> 16) & 0xffff;
            //                                 }
            //                                 else if (d.sims[b].rat == "lte") {
            //                                     q["lcid"].val = d.sims[b]["gcid"] & 0xff;
            //                                     q["neid"].val = d.sims[b]["gcid"] >> 8;
            //                                 }
            //                             }
            //                             devicesLocal.push(q);
            //                         }
            //                     });
            //                     q = undefined;
            //                 }
            //                 else {
            //                     devicesLocal.push(p);
            //                 }
            //             }
            //         } // if ! serialNum
            //     } // if _devices[i]
            // } // for
        }

        return devicesLocal;
        // Return reverse datetime order (most recently connected first)
        //return devicesLocal.sort(function(a, b) {
        //    // Return ascending serialNum order
        //    return a.serialNum == b.serialNum ? 0 : +(a.serialNum > b.serialNum) || -1;

        //    // [TBD] Return descending datetime order (most recently connected first)
        //    //return a.connectedDate == b.connectedDate ? 0 : +(b.connectedDate > a.connectedDate) || -1;
        //});
    }


    // public getBatteryColour(level: number): string
    // {
    //     let colour: string = "";

    //     if (level >= 0) {
    //         if      (level < 10) colour = "red";
    //         else if (level < 15) colour = "orange";
    //         else if (level < 20) colour = "yellow";
    //     }

    //     return colour;
    // }


    // public getNumDevicesPerAttr(attr: string): object
    // {
    //     const collection = [];

    //     for (let i = 0; i < this.total; ++i) {
    //         if (this.devices[i][attr]) {
    //             const value = this.devices[i][attr];
    //             var found = false;
    //             for (let c = 0, len2 = collection.length; c < len2; ++c) {
    //                 if (collection[c].key == value) {
    //                     ++collection[c].val;
    //                     found = true;
    //                 }
    //                 break;
    //             }

    //             if (found != true) {
    //                 //var pair = {x: value, y: 1};
    //                 const pair: {key: any, val: number} = {key: value, val: 1};
    //                 //pair[attr] = value;
    //                 //pair[value] = 1;
    //                 collection.push(pair);
    //             }
    //         }
    //         else {
    //             //console.debug(_devices[i]);
    //         }
    //     } // for

//            for (var i = 0, len = _devices.length; i < len; ++i) {
//                if (_devices[i][attr]) {
//                    var value = _devices[i][attr];
//                    var found = false;
//                    for (var c = 0, len = collection.length; c < len; c++) {
//                        if (collection[c][attr] == value) {
//                            collection[c].num++;
//                            found = true;
//                        }
//                        break;
//                    }
//
//                    if (found != true) {
//                        //var pair = {x: value, y: 1};
//                        var pair = {num: 1};
//                        pair[attr] = value;
//                        //pair[value] = 1;
//                        collection.push(pair);
//                    }
//                }
//                else {
//                    console.debug(_devices[i]);
//                }
//
//            }
//console.debug(collection);
    //     return collection;
    // }
}