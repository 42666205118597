import {
    Pipe,
    PipeTransform
}                     from '@angular/core';

import { AppStrings } from '@Root/';


@Pipe({
    name: 'yesno',
    pure: false,
})
export class YesNoPipe implements PipeTransform
{
    transform(d: boolean): string | undefined
    {
        return d ? AppStrings.Common_Yes : AppStrings.Common_No;
    }
}