import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription
}                                from 'rxjs';
 
import { Base }                  from '@Base/';
import { IconsService }          from '@Icons/'
import {
    DataServiceEvents,
    DataServiceI,
}                                from '@Misc/Services/';
import { MobileNetworksService } from '@MobileNetworks/mobile-networks.service';
import {
    Element,
    Device,
    DeviceI,
    DeviceHelper,
    DeviceMobilePhone,
    DeviceWithBattery,
    Sim
}                                from '@ObjElements/';
import {
    // MobileNetworkBands,
    Utils
}                                from '@Utils/';

//import { DeviceWithBattery }   from '@Devicesdevice/device-types';

// [TBD] notify if device updated


export class DevicesList extends Base implements DataServiceI
{
    private readonly _attrs:                string[]                    = [
        'id',
        'description',
        'location',
        'manufacturer',
        'model',
        'deviceSoftwareLoad'
    ]; // _attrs
    private readonly _devices:               {}                         = {};

    private readonly _dataElements$:         BehaviorSubject<object[]>;
    private readonly _deviceAdded$:          Subject<string>;
    private readonly _deviceDeleted$:        Subject<string>;
    private readonly _numElements$:          BehaviorSubject<number>;
    private readonly _status$:               BehaviorSubject<object>;

    private          _mobileNetworksService: MobileNetworksService;

    private          _type:                  string                     = "";
//    private readonly deviceUpdatedSubject: Subject<string>              = new Subject<string>();
    
    //public  updated: Observable<number>;
    //this.updated       = this.numDevicesSubject.asObservable();
 
 
    public constructor(                 type:          string,
                       private readonly IconsService?: IconsService)
    {
        super();

        this._type = type;

        this._dataElements$  = new BehaviorSubject<object[]>(this.devices);
        this._deviceAdded$   = new Subject<string>();
        this._deviceDeleted$ = new Subject<string>();
        this._numElements$   = new BehaviorSubject<number>(Object.keys(this.devices).length);
        this._status$        = new BehaviorSubject<object>({});

        this.initialise();
    }


    //
    // Interface
    //

    // Override
    public get [DataServiceEvents.added](): Observable<string> | undefined
    {
        return this._deviceAdded$.asObservable();
    }

    // Override
    public get [DataServiceEvents.data](): Observable<object[]> | undefined
    {
        return this._dataElements$.asObservable();
    }

    // Override
    public get [DataServiceEvents.deleted](): Observable<string> | undefined
    {
        return this._deviceDeleted$.asObservable();
    }

    // Override
    public get [DataServiceEvents.loading](): Observable<boolean> | undefined
    {
        return undefined; // [TBD]
    }

    // Override
    public get [DataServiceEvents.number](): Observable<number> | undefined
    {
        return this._numElements$.asObservable();
    }

    // Override
    public get [DataServiceEvents.status](): Observable<string[] | object> | undefined
    {
        return this._status$.asObservable();
    }

    // Override
    public get [DataServiceEvents.updated](): Observable<string> | undefined
    {
        return undefined;// [TBD]
    }


    // Override
    public get(id: string | number, type? : any): Device | undefined
    {
        return this.getDevice(id);
    }


    // Override
    public getL(id: string | number, type? : any): object | Subscription | undefined
    {
        return undefined;
    }


    // Override
    public getObservable(type: string, event: DataServiceEvents): Observable<boolean | number | object | object[] | string | string[]> | undefined
    {
        return event ? this[event] : undefined;
    }

    // Override
    public getAll(type?: any): object[] | undefined
    {
        return this.devices;
    }

        
    //
    // Getters & setters
    //

    // Override
    // public get attrs(): string[]
    // {
    //     return this._attrs;
    // }


    public get deviceIds(): string[]
    {
        return Object.keys(this._devices);
    }


    public get devices(): Device[]
    {
        return Object.values(this._devices);
    }


    private get dataI(): { [key: string]: any }
    {
        return this._devices;
    }


    private get ids(): string[]
    {
        return Object.keys(this._devices);
    }


    // Override
    public override set sub(s: Subscription)
    {
        super.sub = s;
    }


    private get total(): number
    {
        return this.ids.length;
    }

    
    public get type()
    {
        return this._type;
    }


    //
    // Public methods
    //
    public add(d: Device, inform: boolean = true): Device | undefined
    {
        // let sn: string = undefined;//DevicesList.getId(dev);
        // let d1:  Device = this.getDevice(sn);
        
        // Try to update, if fails, means device isn't present so add
        let dev: Element | undefined = this.updateDevice(d);
        if (! dev) {
            // If not found, add as new device
            console.debug("Adding device: " + d.id);

            dev = DeviceHelper.get(d, this.IconsService);
            if (dev instanceof Device) {
                // this.dataI[dev.id] = dev;
                this.updateIcon(this.dataI[dev.id] = dev);
                // sn = dev.id

                this.updateObservables();
                if (this._deviceAdded$ instanceof Observable && inform) this._deviceAdded$.next(dev.id);

                console.debug("Added device: " + dev.id);
                console.debug(dev)
                console.debug(JSON.stringify(dev.elementC))
            }
            else {
                console.warn("Cannot add new device: " + d.id);
                console.warn(d);
                console.warn(dev);
            }
        }

// console.debug("DevicesList");
// console.debug(d);
// console.debug(this.devices);

        // if (d1) {//} && i >= 0 && i < this.total) {
        //     //console.info("Device '" + sn + "' already exists");
        //     this.dataI[d1.id] = this.updateDevice(dev);
        //     //i = this.updateDevice(dev); // update any attributes
        //     //i.status = DeviceStatus.online;
        //     //delete i.pendingDelete; // clear pendingDelete marker
        //     //this.devices[dev.serialNum] = i;
        // }
//         else {
//             // Need to pass iconFn here as classes don't have access to Angular services
//             d = DeviceHelper.get(dev, this.IconsService);
//             if (d) {
//                 this.updateIcon(this.dataI[d2.id] = d);
//                 this.updateObservables();
//                 this._deviceAddedSubject.next(d2.id)
// // Store icon?
//                 sn = d2.id;
//             }
//             else {
//                 console.warn("Invalid device; cannot create new Device object");
//                 console.warn(dev);
//             }

//             if (sn) {
//                 //dev.status = DeviceStatus.online;
//                 // Need to pass iconFn here as classes don't have access to Angular services
//                 this.dataI[sn] = DeviceHelper.get(dev, this.IconsService);
//                 this.updateIcon(this.dataI[sn]);
//                 this.updateObservables();
//                 //console.info("Device '" + sn + "' not found; now added");
//                 this._deviceAddedSubject.next(sn);
// // Store icon?
//             }
//             else {
//                 console.warn("Invalid serial number provided; cannot create new Device object");
//             }
        // }

        // Dynamically create sub object for each class of device - phone, repeater, beacon
        // On device request at start-up, need to send all classes
        // Need to add msg to report addition or deletion of repeater object somehow


        // console.debug(JSON.stringify(dev))

        return (dev instanceof Device) ? dev : undefined;//this.getDevice(dev ? dev.id : undefined);
    }


    // Override
    public override cleanUp()
    {
        console.debug("Cleaning up");
        super.cleanUp()

        this.deleteAll();

        // [TBD] remove when derivative of DataService class
        if (this._dataElements$  instanceof Observable) this._dataElements$.complete();
        if (this._deviceAdded$   instanceof Observable) this._deviceAdded$.complete();
        if (this._deviceDeleted$ instanceof Observable) this._deviceDeleted$.complete();
        if (this._numElements$   instanceof Observable) this._numElements$.complete();
        if (this._status$        instanceof Observable) this._status$.complete();
    }


    public deletePendingDevice(id: string): string
    {
        const d: Device | undefined = this.getDevice(id);
        if (d) {
            d.pendingDelete = true;
           // d.status        = DeviceStatus.offline;
            return id;
        }
        else {
            console.warn("Device '" + id + "' not found; cannot set to pendingDelete");
        }

        return "";
    }


    public delete(id: string, inform: boolean = true): Device | undefined
    {
        const dev: Device | undefined = this.getDevice(id);
        if (dev) {
        //if (d != null) && i >= 0 && i < this.total) {
            // TBD - need to work on this; field to mark deletion?
            // Pages require this entry still to be in place to remove from their views
            // especially the map
            dev.cleanUp();
            delete this.dataI[id];
            //this.devices.splice(i, 1);
        // console.debug("Device '" + this.type + ": " + id + "' found and deleted");
            // *MUST* be done before updateObservables()
            if (this._deviceDeleted$ instanceof Observable && inform) this._deviceDeleted$.next(id);
            this.updateObservables();
        }
        else {
            console.warn("Device '" + id + "' not found; cannot delete");
        }

        return dev;
    }


    public deleteAll(): boolean
    {
        Object.keys(this.dataI).forEach((s: string): Device | undefined => {
            return this.delete(s, false);
        }); // forEach

        return true;
    }


    public updateDevice(d: Device | any): Element | undefined
    {
        // [TBD] Workaround to allow test msgs to work
        if (! (d instanceof Device)) return this.updateDevice2(d);

        // const dev: Device = d instanceof Device ? d : this.getDevice(DevicesList.getId(d));
        const dev: Device | undefined = (d && d instanceof Device) ? this.getDevice(d.id) : undefined;
        if (dev) {
    // console.debug("Update device 1 " + d.id + ", " + dev.id)
    // console.debug("New update: " + d.id + ", " + d.status)
    // console.debug(JSON.stringify(d.elementC))

    // console.debug("Old before: " + dev.id + ", " + dev.status)
    // console.debug(JSON.stringify(dev.elementC))

            // console.debug(JSON.stringify(dev))
            dev.merge(d);

    // console.debug("Old after: " + dev.id + ", " + dev.status)
    // console.debug(JSON.stringify(dev.elementC))
            // console.debug(JSON.stringify(dev))
            this.updateIcon(dev);
            //this.deviceUpdatedSubject.next(dev.serialNum);
    // console.debug("Device found and updated: " + dev.id);

    // console.debug("Update device 2")
            // console.debug(dev)
        }
        // else {
        //     console.warn("Device not found; cannot update: " + d.id);
        //     console.warn(d);
        // }

        return dev;
    }


    // [TBD] Temp to allow test msgs to be processed without obj
    public updateDevice2(d: DeviceI): Element | undefined
    {
        const dev: Device | undefined = d ? this.getDevice(d.id ? d.id : d.serialnumber) : undefined;
        return (dev instanceof Device) ? dev.merge(d) : undefined;
    }


    public getDevice(id: string | number): Device | undefined
    {
        return id ? this.getDeviceById(id) : undefined;
    }


    public getDevices2(): Device[]
    {
        const devs: Device[] = [];

        this.devices.forEach((d: Device) => {
            if (d.pendingDelete) {
                (<any>d).text = d.id + " - " + d.description;
                devs.push(d);
            }
        }); // forEach

        return devs;
    }


   public getDeviceIds(): string[]
    {
        const ids: string[] = [];

        this.devices.forEach((d: Device) => {
            if (d.pendingDelete) ids.push(d.id);
        }); // forEach

        return ids;
    }


    // DataServiceI
    public refresh(): void
    {
        console.debug("Refreshing DevicesList object; nothing to do, wait for update from DeviceCollectionService");
    }


    // DataServiceI
    public refresh2(): void
    {
        this.refresh();
    }


    public refresh3(): void
    {
        this.refresh();
    }


    // public update(data: any): boolean
    // {
    //     console.debug(data);

    //     // Check for valid device types
    //     if (data && data.data) {
    //         Object.keys(data.data).forEach((t: string): void => {
    //             if (Object.values(DevicesCommonModule.deviceTypes).includes(t)) {
    //                 if (this.devices[t]) {
    //                     // Delete all existing devices
    //                     Object.keys(this.devices[t]).forEach((d: string): void => { 
    //                         delete this.devices[t][d];
    //                         this.deviceDeletedSubject.next(d);
    //                     }); // forEach
    //                 }
    //                 else {
    //                     this.devices[t] = {};
    //                 }

    //                 const objs: object[] = data.data[t];
    //                 if (objs && objs.length) {
    //                     console.info("Updating DeviceCollection service with data: " + objs.length);

    //                     // Add devices
    //                     objs.forEach((d: object): void => {
    //                         this.add(d);
    //                     }); // forEach
    //                 }
    //                 else {
    //                     console.warn("No data received to update DeviceCollection service");
    //                     this.deviceDeletedSubject.next();
    //                 }
    //                 this.updateObservables();
    //             }
    //         }); // forEach
    //     } // if data

    //     console.debug(this.devices);

    //     return true;//(objs ? true : false);
    // }

    public setDataAvailable(dSrv: any): void
    {
        if (dSrv) {
            console.debug("Icons available");
            // Update all devices icons
            this.devices.forEach((d: Device): void => {
                this.updateIcon(d);
            }); // forEach
        }
    }


    //
    // Protected methods
    //
    protected override initialise(): void
    {
        console.debug("Initialising DeviceList object: " + this.type);
    }


    //
    // Private methods
    //
    // private getDeviceByAttr(attr: string, val: any) : Device | undefined
    // {
    //     return attr
    //         ? this.devices.find((d: Device): boolean => {
    //             return (d instanceof Device) && d[attr] === val;
    //         }) // find

    //         : undefined;
    // }
    
    
    // private getDeviceById(id: number): Device
    private getDeviceById(id: number | string): Device | undefined
    {
        return this.devices.find((d: Device): boolean => {
            return (!Number.isNaN(id) && d instanceof Device) && d.id === id.toString();
        }) // find

        // return this.getDeviceByAttr('id', id);
    }


    // private getDeviceBySerialNum(id: string): Device
    // {
    //     return this.getDeviceByAttr('id', id);
    //     // //for (var d of this.getDevices()) {//.forEach((d: Device): Device => {
    //     // return this.devices.find((d: Device): boolean => {
    //     //     return (d && d.id === id);
    //     // }); // for
    // }


    // private static getId(d: any)
    // {
    //     return d
    //         ? (d[Messages.attributes.serialnumber]
    //              ? d[Messages.attributes.serialnumber]
    //              : (d.id ? d.id : d._id))
    //         : undefined;
    // }


    private updateIcon(dev: Device): object | undefined
    {
        if (dev) {
            if (dev instanceof DeviceMobilePhone) {
                const s: Sim | undefined = dev.getSim(); // will default to first
                if (s instanceof Sim && this._mobileNetworksService) {
                    this._mobileNetworksService.getIcon("" + s.plmn, s.operator,
                        (i: any): void => { 
                            if (i) dev.icon = i;
                        }
                    ); // getIcon
                } // if s
            }
        } // if d

        return undefined;
    }


    private updateObservables(): void
    {
        if (this._dataElements$ instanceof Observable) this._dataElements$.next(this.devices);
        if (this._numElements$  instanceof Observable) this._numElements$.next(this.total);
        if (this._status$       instanceof Observable) this._status$.next(this.deviceIds);
    }
}