@if (enabled) {
    <!-- [color]="'accent'" -->

    @if (outline) {
        <button mat-stroked-button
            [matTooltip]="label"
            [class]="class_"
            [color]="!class_ ? (! success ? 'warn' : colour) : ''"
            [disabled]="disabled"

            (click)="onClick($event)" 
        >
            <mat-icon [attr.aria-label]="label">{{ icon }}</mat-icon>
            @if (loading) {
                <qp-progress-spinner
                    [diameter]=25
                >
                </qp-progress-spinner>
            }
            @else {
                {{ name }}
            }
        </button>
    }
    
    @else {
        <button mat-button
            [matTooltip]="label"
            [class]="class_"
            [color]="!class_ ? (! success ? 'warn' : colour) : ''"
            [disabled]="disabled"

            (click)="onClick($event)" 
        >
            <mat-icon [attr.aria-label]="label">{{ icon }}</mat-icon>
            @if (loading) {
                <qp-progress-spinner
                    [diameter]=25
                >
                </qp-progress-spinner>
            }
            @else {
                {{ name }}
            }
        </button>
    }
}