import {
    Component,
    EventEmitter,
    Input,
    Output
}                                 from '@angular/core';

import { BaseComponent }          from '@Base/';
import {
    TableDataComponent,
    TableDataValue
}                                 from '@GuiElements/table-data.component';
import { YesNoPipe }              from '@GuiElements/yesno.pipe';
import { MaterialCustomModule }   from '@Material/';

import { DeviceCommon as Device } from '@ObjElements/devices/device/';


@Component({
    selector:    'qp-devicesurveyrunning',
    templateUrl: 'devicesurveyrunning.component.html',
    styleUrls:   [
        'devicesurveyrunning.component.css'
    ],
    imports:     [
        YesNoPipe,
        TableDataComponent,
        
        MaterialCustomModule
    ]
})
export class DeviceSurveyRunningComponent extends BaseComponent
{
    @Input()
    public d: Device;


    @Output()
    public readonly selected: EventEmitter<TableDataValue> = new EventEmitter();


    //
    // Public methods
    //
    public select(d: TableDataValue)
    {
        this.selected.emit(d);
    }
}