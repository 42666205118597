import {
    Component,
    Input
}                                   from '@angular/core';

import { MaterialCustomModule }     from '@Material/';

import { ButtonBaseComponent }      from './button-base.component';
import { ProgressSpinnerComponent } from './progress-spinner.component';


@Component({
    selector:    'qp-button',
    templateUrl: 'button.component.html',
    styleUrls:   [
        'button.component.css'
    ],
    imports:     [
        MaterialCustomModule,

        ProgressSpinnerComponent
    ]
})
export class ButtonComponent extends ButtonBaseComponent
{
    @Input()
    public class_: string | undefined;


    @Input()
    public colour: string | undefined = "accent";


    @Input()
    public disabled: boolean | undefined = false;


    @Input()
    public icon: string;


    @Input()
    public outline: boolean = true;
}